<template>
    <div>
        <div class="zozo-main-wrapper">
            <div id="main" class="main-section">
                <div data-zozo-parallax="2" data-zozo-parallax-position="left top"
                    class="page-title-section page-titletype-default page-titleskin-default page-titlealign-default zozo-parallax"
                    v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                    <div class="page-title-wrapper clearfix">
                        <div class="container">
                            <div class="page-title-container">
                                <div class="page-title-captions">
                                    <h1 class="entry-title " style="font-family: 'Cairo' !important;">
                                        {{ $t("header.Blog") }}</h1>
                                </div>
                                <div class="page-title-breadcrumbs">
                                    <div id="breadcrumb" class=""
                                        v-bind:class="[{'text_en' : $i18n.locale=='ar' },{'text_Ar':$i18n.locale=='en'}]">
                                        <router-link to="/" style="font-family: 'Cairo' !important;">
                                            {{ $t("header.home") }}</router-link>
                                        <i class="fas fa-angle-left" style="margin-left:5px;margin-right:5px"></i>
                                        <span class="current"
                                            style="font-family: 'Cairo' !important;">{{ $t("header.Blog") }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="zozo_parallax-inner" data-center="top: 0%;" data-bottom-top="top: 0%;"
                        data-top-bottom="top: -100%;" style="height: 200%; bottom: 0px;"></div>
                </div>


                <div class="container">

                    <div id="main-wrapper" class="zozo-row row">
                        <div id="single-sidebar-container" class="single-sidebar-container main-col-full">
                            <div class="zozo-row row"
                                v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                                <div id="primary" class="content-area content-col-small">
                                    <div id="content" class="site-content">
                                        <article id="post-142"
                                            class="post-142 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-featured">
                                            <div class="post-inner-wrapper">
                                                <div class="post-featured-image only-image">
                                                    <div class="entry-thumbnail-wrapper">
                                                        <div class="entry-thumbnail">
                                                            <ShareNetwork :popup="{width: 400, height: 200}"
                                                                v-for="network in networks" :network="network.network"
                                                                :key="network.network" :url="sharing.url"
                                                                :title="sharing.title"
                                                                :description="sharing.description"
                                                                :quote="sharing.quote" :hashtags="sharing.hashtags"
                                                                :twitterUser="sharing.twitterUser">
                                                                <i :class="network.icon"></i>

                                                            </ShareNetwork>
                                                            <p
                                                                style="font-family: 'Cairo' !important;font-size:19px;padding-bottom:20px">

                                                                {{art.title}}
                                                            </p>


                                                            <video v-if="art.video_file" id='my-video' class='video-js'
                                                                controls preload='auto' style="width:100%"
                                                                :poster="Url+'/art_img/art_img_main/'+art.image.image_name"
                                                                height='464' data-setup='{}'>
                                                                <source :src="Url+'/videos_file/'+art.video_file"
                                                                    type='video/mp4'>
                                                                <source :src="Url+'/videos_file/'+art.video_file"
                                                                    type='video/webm'>
                                                 
                                                            </video>

                                                            <div v-else >
                                                                <a :data-fancybox="art" style="height:450px;width:100%"
                                                                    :href="Url+'/art_img/'+art.image.image_name">
                                                                    <v-img style="height:100%;width:100%"
                                                                        :src="Url+'/art_img/art_img_main/'+art.image.image_name"

                                                                        :lazy-src="Url+'/art_img/small_thumb/'+art.image.image_name"
                                                                         aspect-ratio="1"
                                                                         
                                                                        class="attachment-charity-blog-large size-charity-blog-large wp-post-image"
                                                                        alt="" loading="lazy" />
                                                                </a>
                                                            </div>




                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="posts-content-container">
                                                    <div class="entry-content">
                                                        <p style="font-family: 'Cairo' !important">{{art.description}}.
                                                        </p>


                                                    </div>

                                                </div>
                                            </div>
                                        </article>


                                        <br>
                                        <br>
                                        <br>


                                        <!--attached_imgs-->
                                        <v-layout row wrap>

                                            <v-flex xs12 md3 sm3 class="attached_img" style="    margin-top: 10px"
                                                v-for="(item,i) in art.images" :key="i" pt-3 pb-3>


                                                <a :data-fancybox="art" :href="Url+'/art_img/'+item.image_name">
                                                    <div style="width:90%;height:170px;">


                                                        <v-img style="width:100%;height:100%"

                                                                                    :src="Url+'/art_img/thumb_new/'+item.image_name"
                                                                 :lazy-src="Url+'/art_img/small_thumb/'+item.image_name"


                                                          
                                                            >

                                                        </v-img>


                                                    </div>
                                                </a>


                                            </v-flex>

                                        </v-layout>




                                    </div>
                                </div>
                                <div id="sidebar" class="primary-sidebar sidebar pm-sidebar"
                                    v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">

                                    <div id="zozo_popular_posts-widget-1" class="widget zozo_popular_posts_widget">
                                        <h3 class="widget-title" style="font-family: 'Cairo' !important">
                                            {{ $t("latest_work") }}</h3>
                                        <div id="zozo_latest_posts_widget" class="zozo-latest-posts">
                                            <ul class="latest-posts-menu list-unstyled">
                                                <li class="posts-item clearfix" v-for="art in arts" :key="art">

                                                    <router-link :to="{ name: 'art', params: { id:art.id }}"
                                                        style="    color: #3d3d3d !important">
                                                        <div class="latest-post-img"> <img
                                                                class="img-responsive latest-post-img"
                                                                :src="Url+'/art_img/thumb_new/'+art.image.image_name"

                                                                width="150" height="150" alt="Donate Clothes"></div>
                                                        <div class="latest-post-content">
                                                            <h6 class="posts-title"><a
                                                                    href="http://themes.zozothemes.com/charity/2017/03/23/donate-clothes/"
                                                                    title="Donate Clothes">{{art.articles_cats.name}}</a>
                                                            </h6>
                                                            <div class="entry-summary">
                                                                <p style="font-family: 'Cairo' !important">
                                                                    {{art.title|subStrAds}}</p>
                                                            </div>
                                                            <div class="entry-date posted-date"><i
                                                                    class="far fa-calendar-alt"></i>{{art.created_at|formatDate}}
                                                            </div>
                                                        </div>

                                                    </router-link>

                                                </li>

                                            </ul>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<style>
    .entry-thumbnail a {
        display: inline-block !important;
        padding: 10px
    }

    .share-network-facebook {
        display: inline-block !important;
    }
</style>
<script>
    import Axios from "axios";
    import {
        EventBus
    } from '../../components/core/event-bus.js';


    export default {
        metaInfo() {
            return {
                title: this.$t(this.$route.name)
                // meta: [
                //     { name: 'description', content:  'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'},
                //     { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
                //     { property: 'og:site_name', content: 'Epiloge'},
                //     {property: 'og:type', content: 'website'},    
                //     {name: 'robots', content: 'index,follow'} ,
                //     {name: 'og:image', content: 'https://abnakr.org/back_end/art_img/art_img_main/img1636928749.png'} 


                // ]
                // ,
                ///  
            }
        },
        data: () => ({
            art: {
                image: {
                    image_name: ''
                },
                title: ''
            },


            arts: [],
            sharing: {
                url: '',
                title: '',
                description: '',
                quote: '',
                hashtags: '',
                twitterUser: ''
            },
            networks: [


                {
                    network: 'email',
                    name: 'Email',
                    icon: 'far fah fa-lg fa-envelope',
                    color: '#333333'
                },
                {
                    network: 'facebook',
                    name: 'Facebook',
                    icon: 'fab fah fa-lg fa-facebook-f',
                    color: '#1877f2'
                },
                // { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
                // { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
                //{ network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
                {
                    network: 'telegram',
                    name: 'Telegram',
                    icon: 'fab fah fa-lg fa-telegram-plane',
                    color: '#0088cc'
                },
                {
                    network: 'twitter',
                    name: 'Twitter',
                    icon: 'fab fah fa-lg fa-twitter',
                    color: '#1da1f2'
                },
                {
                    network: 'viber',
                    name: 'Viber',
                    icon: 'fab fah fa-lg fa-viber',
                    color: '#59267c'
                },
                {
                    network: 'whatsapp',
                    name: 'Whatsapp',
                    icon: 'fab fah fa-lg fa-whatsapp',
                    color: '#25d366'
                },

            ]

        }),

        methods: {
            getArt() {
                this.loading = true;
                Axios.get("/articles/GetByLang/5", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.arts = res.data.data;

                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },



            Get_ById() {

                Axios.get('/articles/' + this.$route.params.id, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(response => {
                        this.loading = false

                        this.art = response.data.data[0]



                        this.sharing.url = 'http://abnakr.org/art/' + this.$route.params.id;
                        this.sharing.title = this.art.title,
                            this.sharing.description = this.art.title,
                            this.sharing.quote = this.art.title,
                            // this.sharing.hashtags= this.art.title,
                            this.sharing.twitterUser = this.art.title


                        if (this.art.length == 0) {

                            return this.$router.push({
                                name: "home"
                            })



                        }





                    })



            },

            changeLang() {


            },
        },
        filters: {

            subStrAds: function (string) {

                if (string != null) {
                    return string.substring(0, 80) + ' ... ';
                }

            },

            subStrSlider: function (string) {

                if (string != null) {
                    return string.substring(0, 100) + ' ... ';
                }

            },
            subStrArtDetailss_lastt: function (string) {

                if (string != null) {
                    return string.substring(0, 140) + '... ألمزيد';
                }

            }





        },


        watch: {


            '$route': function () {

                //  this.GetComments();
                Axios.get('/articles/' + this.$route.params.id, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })

                    .then(response => {

                        this.art = response.data.data[0]
                        this.loading = false

                        if (this.art.length == 0) {
                            return this.$router.push({
                                name: "ألرئيسية"
                            })



                        }





                    })



                //  this.increase_visitores(this.$route.params.id);
            },


            Get_ById() {
                Axios.get('/articles/' + this.$route.params.id, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })


                    .then(response => {
                        this.loading = false

                        this.art = response.data

                        if (this.art.length == 0) {

                            return this.$router.push({
                                name: "home"
                            })



                        }





                    })



            },



        },
        created() {
            EventBus.$on("changeLangs", (from) => {
                from
                this.Get_ById();
                this.getArt()



            });

            this.Get_ById();
            this.getArt()
        },

    }
</script>